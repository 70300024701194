var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageTitle", [_vm._v("Scrims Stats")]),
      _c(
        "ApiLoadingController",
        { ref: "loadingController", attrs: { fetch: _vm.getScrims } },
        [
          [
            _c(
              "AppTabs",
              [
                _vm._l(_vm.maps, function(m) {
                  return [
                    _c("AppTab", { key: m, attrs: { title: m } }, [
                      _c(
                        "div",
                        [
                          _c("SelectedMapStats", {
                            attrs: { "map-name": m, data: _vm.scrims[m] }
                          })
                        ],
                        1
                      )
                    ])
                  ]
                })
              ],
              2
            )
          ]
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }