var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("AppTable", {
        attrs: { items: _vm.stats, fields: _vm.fields },
        scopedSlots: _vm._u([
          {
            key: "thead-top",
            fn: function() {
              return [
                _c(
                  "b-tr",
                  [
                    _c(
                      "b-th",
                      {
                        staticStyle: { "text-align": "left" },
                        attrs: { colspan: "4" }
                      },
                      [_vm._v(" OVERALL WINRATE ")]
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "cell(comp)",
            fn: function(field) {
              return [
                field.item.comp === "ALL"
                  ? _c("div", [_vm._v("ALL")])
                  : _c(
                      "div",
                      [
                        _c("AgentComposition", {
                          attrs: { agent_ids: field.item.comp, size: "sm" }
                        })
                      ],
                      1
                    )
              ]
            }
          },
          {
            key: "cell(match)",
            fn: function(field) {
              return [
                _c("div", [
                  _c("strong", [
                    _vm._v(
                      _vm._s(
                        _vm._f("percentage")(
                          field.item.match.wins,
                          field.item.match.total
                        )
                      )
                    )
                  ]),
                  _c("span", { staticClass: "text-muted ml-1" }, [
                    _vm._v(
                      "(" +
                        _vm._s(field.item.match.wins) +
                        " / " +
                        _vm._s(field.item.match.total) +
                        ")"
                    )
                  ])
                ])
              ]
            }
          },
          {
            key: "cell(atk)",
            fn: function(field) {
              return [
                _c("div", [
                  _c("strong", [
                    _vm._v(
                      _vm._s(
                        _vm._f("percentage")(
                          field.item.atk.wins,
                          field.item.atk.total
                        )
                      )
                    )
                  ]),
                  _c("span", { staticClass: "text-muted ml-1" }, [
                    _vm._v(
                      "(" +
                        _vm._s(field.item.atk.wins) +
                        " / " +
                        _vm._s(field.item.atk.total) +
                        ")"
                    )
                  ])
                ])
              ]
            }
          },
          {
            key: "cell(def)",
            fn: function(field) {
              return [
                _c("div", [
                  _c("strong", [
                    _vm._v(
                      _vm._s(
                        _vm._f("percentage")(
                          field.item.def.wins,
                          field.item.def.total
                        )
                      )
                    )
                  ]),
                  _c("span", { staticClass: "text-muted ml-1" }, [
                    _vm._v(
                      "(" +
                        _vm._s(field.item.def.wins) +
                        " / " +
                        _vm._s(field.item.def.total) +
                        ")"
                    )
                  ])
                ])
              ]
            }
          }
        ])
      }),
      _c("AppTable", {
        attrs: { items: _vm.stats, fields: _vm.fields },
        scopedSlots: _vm._u([
          {
            key: "thead-top",
            fn: function() {
              return [
                _c(
                  "b-tr",
                  [
                    _c(
                      "b-th",
                      {
                        staticStyle: { "text-align": "left" },
                        attrs: { colspan: "4" }
                      },
                      [_vm._v(" PISTOL WINRATE ")]
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "cell(comp)",
            fn: function(field) {
              return [
                field.item.comp === "ALL"
                  ? _c("div", [_vm._v("ALL")])
                  : _c(
                      "div",
                      [
                        _c("AgentComposition", {
                          attrs: { agent_ids: field.item.comp, size: "sm" }
                        })
                      ],
                      1
                    )
              ]
            }
          },
          {
            key: "cell(match)",
            fn: function(field) {
              return [
                _c("div", [
                  _c("strong", [
                    _vm._v(
                      _vm._s(
                        _vm._f("percentage")(
                          field.item.match.wins,
                          field.item.match.total
                        )
                      )
                    )
                  ]),
                  _c("span", { staticClass: "text-muted ml-1" }, [
                    _vm._v(
                      "(" +
                        _vm._s(field.item.match.wins) +
                        " / " +
                        _vm._s(field.item.match.total) +
                        ")"
                    )
                  ])
                ])
              ]
            }
          },
          {
            key: "cell(atk)",
            fn: function(field) {
              return [
                _c("div", [
                  _c("strong", [
                    _vm._v(
                      _vm._s(
                        _vm._f("percentage")(
                          field.item.atk.pistol_wins,
                          field.item.atk.pistol_total
                        )
                      )
                    )
                  ]),
                  _c("span", { staticClass: "text-muted ml-1" }, [
                    _vm._v(
                      "(" +
                        _vm._s(field.item.atk.pistol_wins) +
                        " / " +
                        _vm._s(field.item.atk.pistol_total) +
                        ")"
                    )
                  ])
                ])
              ]
            }
          },
          {
            key: "cell(def)",
            fn: function(field) {
              return [
                _c("div", [
                  _c("strong", [
                    _vm._v(
                      _vm._s(
                        _vm._f("percentage")(
                          field.item.def.pistol_wins,
                          field.item.def.pistol_total
                        )
                      )
                    )
                  ]),
                  _c("span", { staticClass: "text-muted ml-1" }, [
                    _vm._v(
                      "(" +
                        _vm._s(field.item.def.pistol_wins) +
                        " / " +
                        _vm._s(field.item.def.pistol_total) +
                        ")"
                    )
                  ])
                ])
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }