var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "buttonGroup" }, [
        _c(
          "button",
          {
            class: { active: _vm.fkdFilter === "all" },
            on: {
              click: function($event) {
                return _vm.clickFKDFilter("all")
              }
            }
          },
          [_vm._v("All")]
        ),
        _c(
          "button",
          {
            class: { active: _vm.fkdFilter === "true" },
            on: {
              click: function($event) {
                return _vm.clickFKDFilter("true")
              }
            }
          },
          [_vm._v("True")]
        ),
        _c(
          "button",
          {
            class: { active: _vm.fkdFilter === "traded" },
            on: {
              click: function($event) {
                return _vm.clickFKDFilter("traded")
              }
            }
          },
          [_vm._v("Traded")]
        ),
        _vm._m(0)
      ]),
      _c("AppTable", {
        attrs: { items: _vm.stats, fields: _vm.fkdFields },
        scopedSlots: _vm._u([
          {
            key: "thead-top",
            fn: function() {
              return [
                _c(
                  "b-tr",
                  [
                    _c(
                      "b-th",
                      {
                        staticStyle: { "text-align": "left" },
                        attrs: { colspan: "7" }
                      },
                      [_vm._v(" FIRST KILL/DEATH ")]
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "cell(comp)",
            fn: function(field) {
              return [
                field.item.comp === "ALL"
                  ? _c("div", [_vm._v("ALL")])
                  : _c(
                      "div",
                      [
                        _c("AgentComposition", {
                          attrs: { agent_ids: field.item.comp, size: "sm" }
                        })
                      ],
                      1
                    )
              ]
            }
          },
          {
            key: "cell(atk)",
            fn: function(field) {
              return [
                _c("div", [
                  _c("strong", [_vm._v(_vm._s(field.item.atk.total))])
                ])
              ]
            }
          },
          {
            key: "cell(atk_fk)",
            fn: function(field) {
              return [
                _c("div", [
                  _c("strong", [
                    _vm._v(
                      _vm._s(
                        _vm._f("percentage")(
                          field.item.atk[
                            _vm.fkdKeys[_vm.fkdFilter] + "fk_wins"
                          ],
                          field.item.atk[
                            _vm.fkdKeys[_vm.fkdFilter] + "fk_total"
                          ]
                        )
                      )
                    )
                  ]),
                  _c("span", { staticClass: "text-muted ml-1" }, [
                    _vm._v(
                      "(" +
                        _vm._s(
                          field.item.atk[_vm.fkdKeys[_vm.fkdFilter] + "fk_wins"]
                        ) +
                        " / " +
                        _vm._s(
                          field.item.atk[
                            _vm.fkdKeys[_vm.fkdFilter] + "fk_total"
                          ]
                        ) +
                        ")"
                    )
                  ])
                ])
              ]
            }
          },
          {
            key: "cell(atk_fd)",
            fn: function(field) {
              return [
                _c("div", [
                  _c("strong", [
                    _vm._v(
                      _vm._s(
                        _vm._f("percentage")(
                          field.item.atk[
                            _vm.fkdKeys[_vm.fkdFilter] + "fd_wins"
                          ],
                          field.item.atk[
                            _vm.fkdKeys[_vm.fkdFilter] + "fd_total"
                          ]
                        )
                      )
                    )
                  ]),
                  _c("span", { staticClass: "text-muted ml-1" }, [
                    _vm._v(
                      "(" +
                        _vm._s(
                          field.item.atk[_vm.fkdKeys[_vm.fkdFilter] + "fd_wins"]
                        ) +
                        " / " +
                        _vm._s(
                          field.item.atk[
                            _vm.fkdKeys[_vm.fkdFilter] + "fd_total"
                          ]
                        ) +
                        ")"
                    )
                  ])
                ])
              ]
            }
          },
          {
            key: "cell(def)",
            fn: function(field) {
              return [
                _c("div", [
                  _c("strong", [_vm._v(_vm._s(field.item.def.total))])
                ])
              ]
            }
          },
          {
            key: "cell(def_fk)",
            fn: function(field) {
              return [
                _c("div", [
                  _c("strong", [
                    _vm._v(
                      _vm._s(
                        _vm._f("percentage")(
                          field.item.def[
                            _vm.fkdKeys[_vm.fkdFilter] + "fk_wins"
                          ],
                          field.item.def[
                            _vm.fkdKeys[_vm.fkdFilter] + "fk_total"
                          ]
                        )
                      )
                    )
                  ]),
                  _c("span", { staticClass: "text-muted ml-1" }, [
                    _vm._v(
                      "(" +
                        _vm._s(
                          field.item.def[_vm.fkdKeys[_vm.fkdFilter] + "fk_wins"]
                        ) +
                        " / " +
                        _vm._s(
                          field.item.def[
                            _vm.fkdKeys[_vm.fkdFilter] + "fk_total"
                          ]
                        ) +
                        ")"
                    )
                  ])
                ])
              ]
            }
          },
          {
            key: "cell(def_fd)",
            fn: function(field) {
              return [
                _c("div", [
                  _c("strong", [
                    _vm._v(
                      _vm._s(
                        _vm._f("percentage")(
                          field.item.def[
                            _vm.fkdKeys[_vm.fkdFilter] + "fd_wins"
                          ],
                          field.item.def[
                            _vm.fkdKeys[_vm.fkdFilter] + "fd_total"
                          ]
                        )
                      )
                    )
                  ]),
                  _c("span", { staticClass: "text-muted ml-1" }, [
                    _vm._v(
                      "(" +
                        _vm._s(
                          field.item.def[_vm.fkdKeys[_vm.fkdFilter] + "fd_wins"]
                        ) +
                        " / " +
                        _vm._s(
                          field.item.def[
                            _vm.fkdKeys[_vm.fkdFilter] + "fd_total"
                          ]
                        ) +
                        ")"
                    )
                  ])
                ])
              ]
            }
          }
        ])
      }),
      _c("div", { staticClass: "buttonGroup" }, [
        _c(
          "button",
          {
            class: { active: _vm.firstStateFilter === "adv" },
            on: {
              click: function($event) {
                return _vm.clickFirstStateFilter("adv")
              }
            }
          },
          [_vm._v(" Man Advantage ")]
        ),
        _c(
          "button",
          {
            class: { active: _vm.firstStateFilter === "eq" },
            on: {
              click: function($event) {
                return _vm.clickFirstStateFilter("eq")
              }
            }
          },
          [_vm._v("Equal")]
        ),
        _c(
          "button",
          {
            class: { active: _vm.firstStateFilter === "dadv" },
            on: {
              click: function($event) {
                return _vm.clickFirstStateFilter("dadv")
              }
            }
          },
          [_vm._v(" Man Disadvantage ")]
        ),
        _c(
          "button",
          {
            staticClass: "stableButton",
            on: { click: _vm.clickFirstStateStable }
          },
          [
            _vm._v(" Only Stable States: "),
            _vm.firstStateStable ? [_vm._v("Yes")] : [_vm._v("No")]
          ],
          2
        ),
        _vm._m(1)
      ]),
      _c("AppTable", {
        attrs: { items: _vm.firstStateStats, fields: _vm.firstStateFields },
        scopedSlots: _vm._u([
          {
            key: "thead-top",
            fn: function() {
              return [
                _c(
                  "b-tr",
                  [
                    _c(
                      "b-th",
                      {
                        staticStyle: { "text-align": "left" },
                        attrs: { colspan: "9" }
                      },
                      [_vm._v(" FIRST STATE ")]
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "cell(comp)",
            fn: function(row) {
              return [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover.noninteractive",
                        modifiers: { hover: true, noninteractive: true }
                      }
                    ],
                    staticStyle: {
                      display: "inline-flex",
                      "align-items": "center"
                    },
                    attrs: { title: row.item.title },
                    on: { click: row.toggleDetails }
                  },
                  [
                    _c("BIconCaretRightFill", {
                      staticClass: "toggle-details-icon",
                      class: row.detailsShowing ? "expanded" : "collapsed"
                    }),
                    row.item.comp === "ALL"
                      ? _c("div", [_vm._v("ALL")])
                      : _c(
                          "div",
                          [
                            _c("AgentComposition", {
                              attrs: { agent_ids: row.item.comp, size: "sm" }
                            })
                          ],
                          1
                        )
                  ],
                  1
                )
              ]
            }
          },
          {
            key: "cell(atk)",
            fn: function(field) {
              return [
                _c("div", [
                  _c("strong", [_vm._v(_vm._s(field.item.atk.total))])
                ])
              ]
            }
          },
          {
            key: "cell(atk_adv)",
            fn: function(field) {
              return [
                _c("div", [
                  _c("strong", [
                    _vm._v(
                      _vm._s(
                        _vm._f("percentage")(
                          field.item.atk.adv_wins,
                          field.item.atk.adv_total
                        )
                      )
                    )
                  ]),
                  _c("span", { staticClass: "text-muted ml-1" }, [
                    _vm._v(
                      "(" +
                        _vm._s(field.item.atk.adv_wins) +
                        " / " +
                        _vm._s(field.item.atk.adv_total) +
                        ")"
                    )
                  ])
                ])
              ]
            }
          },
          {
            key: "cell(atk_eq)",
            fn: function(field) {
              return [
                _c("div", [
                  _c("strong", [
                    _vm._v(
                      _vm._s(
                        _vm._f("percentage")(
                          field.item.atk.eq_wins,
                          field.item.atk.eq_total
                        )
                      )
                    )
                  ]),
                  _c("span", { staticClass: "text-muted ml-1" }, [
                    _vm._v(
                      "(" +
                        _vm._s(field.item.atk.eq_wins) +
                        " / " +
                        _vm._s(field.item.atk.eq_total) +
                        ")"
                    )
                  ])
                ])
              ]
            }
          },
          {
            key: "cell(atk_dadv)",
            fn: function(field) {
              return [
                _c("div", [
                  _c("strong", [
                    _vm._v(
                      _vm._s(
                        _vm._f("percentage")(
                          field.item.atk.dadv_wins,
                          field.item.atk.dadv_total
                        )
                      )
                    )
                  ]),
                  _c("span", { staticClass: "text-muted ml-1" }, [
                    _vm._v(
                      "(" +
                        _vm._s(field.item.atk.dadv_wins) +
                        " / " +
                        _vm._s(field.item.atk.dadv_total) +
                        ")"
                    )
                  ])
                ])
              ]
            }
          },
          {
            key: "cell(def)",
            fn: function(field) {
              return [
                _c("div", [
                  _c("strong", [_vm._v(_vm._s(field.item.def.total))])
                ])
              ]
            }
          },
          {
            key: "cell(def_adv)",
            fn: function(field) {
              return [
                _c("div", [
                  _c("strong", [
                    _vm._v(
                      _vm._s(
                        _vm._f("percentage")(
                          field.item.def.adv_wins,
                          field.item.def.adv_total
                        )
                      )
                    )
                  ]),
                  _c("span", { staticClass: "text-muted ml-1" }, [
                    _vm._v(
                      "(" +
                        _vm._s(field.item.def.adv_wins) +
                        " / " +
                        _vm._s(field.item.def.adv_total) +
                        ")"
                    )
                  ])
                ])
              ]
            }
          },
          {
            key: "cell(def_eq)",
            fn: function(field) {
              return [
                _c("div", [
                  _c("strong", [
                    _vm._v(
                      _vm._s(
                        _vm._f("percentage")(
                          field.item.def.eq_wins,
                          field.item.def.eq_total
                        )
                      )
                    )
                  ]),
                  _c("span", { staticClass: "text-muted ml-1" }, [
                    _vm._v(
                      "(" +
                        _vm._s(field.item.def.eq_wins) +
                        " / " +
                        _vm._s(field.item.def.eq_total) +
                        ")"
                    )
                  ])
                ])
              ]
            }
          },
          {
            key: "cell(def_dadv)",
            fn: function(field) {
              return [
                _c("div", [
                  _c("strong", [
                    _vm._v(
                      _vm._s(
                        _vm._f("percentage")(
                          field.item.def.dadv_wins,
                          field.item.def.dadv_total
                        )
                      )
                    )
                  ]),
                  _c("span", { staticClass: "text-muted ml-1" }, [
                    _vm._v(
                      "(" +
                        _vm._s(field.item.def.dadv_wins) +
                        " / " +
                        _vm._s(field.item.def.dadv_total) +
                        ")"
                    )
                  ])
                ])
              ]
            }
          },
          {
            key: "row-details",
            fn: function(row) {
              return [
                _c("h3", [
                  _vm._v(_vm._s(_vm.firstStateTitle[_vm.firstStateFilter]))
                ]),
                _c(
                  "div",
                  { staticStyle: { display: "flex", "flex-direction": "row" } },
                  [
                    _c("AppTable", {
                      attrs: {
                        items: row.item.atk[_vm.firstStateFilter + "_items"],
                        fields: _vm.xvyFields
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "thead-top",
                            fn: function() {
                              return [
                                _c(
                                  "b-tr",
                                  [
                                    _c(
                                      "b-th",
                                      {
                                        staticClass: "atk",
                                        attrs: { colspan: "2" }
                                      },
                                      [_vm._v("ATK")]
                                    )
                                  ],
                                  1
                                )
                              ]
                            },
                            proxy: true
                          },
                          {
                            key: "cell(wr)",
                            fn: function(field) {
                              return [
                                _c("div", [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("percentage")(
                                          field.item.wr.wins,
                                          field.item.wr.total
                                        )
                                      )
                                    )
                                  ]),
                                  _c(
                                    "span",
                                    { staticClass: "text-muted ml-1" },
                                    [
                                      _vm._v(
                                        "(" +
                                          _vm._s(field.item.wr.wins) +
                                          " / " +
                                          _vm._s(field.item.wr.total) +
                                          ")"
                                      )
                                    ]
                                  )
                                ])
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    }),
                    _c("AppTable", {
                      attrs: {
                        items: row.item.def[_vm.firstStateFilter + "_items"],
                        fields: _vm.xvyFields
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "thead-top",
                            fn: function() {
                              return [
                                _c(
                                  "b-tr",
                                  [
                                    _c(
                                      "b-th",
                                      {
                                        staticClass: "def",
                                        attrs: { colspan: "2" }
                                      },
                                      [_vm._v(" DEF")]
                                    )
                                  ],
                                  1
                                )
                              ]
                            },
                            proxy: true
                          },
                          {
                            key: "cell(wr)",
                            fn: function(field) {
                              return [
                                _c("div", [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("percentage")(
                                          field.item.wr.wins,
                                          field.item.wr.total
                                        )
                                      )
                                    )
                                  ]),
                                  _c(
                                    "span",
                                    { staticClass: "text-muted ml-1" },
                                    [
                                      _vm._v(
                                        "(" +
                                          _vm._s(field.item.wr.wins) +
                                          " / " +
                                          _vm._s(field.item.wr.total) +
                                          ")"
                                      )
                                    ]
                                  )
                                ])
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    })
                  ],
                  1
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tooltipmain" }, [
      _vm._v(" ? "),
      _c("span", { staticClass: "tooltiptext" }, [
        _vm._v(
          " True First Kills/Deaths indicate that the opening kill was not traded. A Trade is a kill towards the victim's killer within 3 seconds. True FK is the favored stat because your team won the opening duel without losing the player who got the FK. Traded FD is the favored stat because of immediately equalizing after losing the opening duel. "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tooltipmain" }, [
      _vm._v(" ? "),
      _c("span", { staticClass: "tooltiptext" }, [
        _vm._v(
          " An FK/D does not always mean a perfect 5v4 or 4v5 situation. You can get a True First Kill while at the same time lose a player at a different part of the map (meaning a 4v4 not a 5v4). A Stable State means no kills were made after 3 seconds from the latest kill. Sometimes there are no stable first states like in a 5-man rush where a whole team dies w/o 3 seconds in between player deaths, so we just record a 4v5/5v4. "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }