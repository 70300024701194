var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("AppTable", {
        attrs: { items: _vm.stats, fields: _vm.fields },
        scopedSlots: _vm._u([
          {
            key: "thead-top",
            fn: function() {
              return [
                _c(
                  "b-tr",
                  [
                    _c(
                      "b-th",
                      {
                        staticStyle: { "text-align": "left" },
                        attrs: { colspan: "5" }
                      },
                      [_vm._v(" POST-PLANT ")]
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "cell(comp)",
            fn: function(row) {
              return [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover.noninteractive",
                        modifiers: { hover: true, noninteractive: true }
                      }
                    ],
                    staticStyle: {
                      display: "inline-flex",
                      "align-items": "center"
                    },
                    attrs: { title: row.item.title }
                  },
                  [
                    _c("BIconCaretRightFill", {
                      staticClass: "toggle-details-icon",
                      class: row.detailsShowing ? "expanded" : "collapsed",
                      on: { click: row.toggleDetails }
                    }),
                    row.item.comp === "ALL"
                      ? _c("div", [_vm._v("ALL")])
                      : _c(
                          "div",
                          [
                            _c("AgentComposition", {
                              attrs: { agent_ids: row.item.comp, size: "sm" }
                            })
                          ],
                          1
                        )
                  ],
                  1
                )
              ]
            }
          },
          {
            key: "cell(atk)",
            fn: function(field) {
              return [
                _c("div", [
                  _c("strong", [_vm._v(_vm._s(field.item.atk.total))])
                ])
              ]
            }
          },
          {
            key: "cell(atk_plants)",
            fn: function(field) {
              return [
                _c("div", [
                  _c("strong", [
                    _vm._v(
                      _vm._s(
                        _vm._f("percentage")(
                          field.item.atk.postplant_wins,
                          field.item.atk.postplant_total
                        )
                      )
                    )
                  ]),
                  _c("span", { staticClass: "text-muted ml-1" }, [
                    _vm._v(
                      "(" +
                        _vm._s(field.item.atk.postplant_wins) +
                        " / " +
                        _vm._s(field.item.atk.postplant_total) +
                        ")"
                    )
                  ])
                ])
              ]
            }
          },
          {
            key: "cell(def)",
            fn: function(field) {
              return [
                _c("div", [
                  _c("strong", [_vm._v(_vm._s(field.item.def.total))])
                ])
              ]
            }
          },
          {
            key: "cell(def_plants)",
            fn: function(field) {
              return [
                _c("div", [
                  _c("strong", [
                    _vm._v(
                      _vm._s(
                        _vm._f("percentage")(
                          field.item.def.postplant_wins,
                          field.item.def.postplant_total
                        )
                      )
                    )
                  ]),
                  _c("span", { staticClass: "text-muted ml-1" }, [
                    _vm._v(
                      "(" +
                        _vm._s(field.item.def.postplant_wins) +
                        " / " +
                        _vm._s(field.item.def.postplant_total) +
                        ")"
                    )
                  ])
                ])
              ]
            }
          },
          {
            key: "row-details",
            fn: function(row) {
              return [
                _c(
                  "div",
                  { staticStyle: { display: "flex", "flex-direction": "row" } },
                  [
                    _c("AppTable", {
                      attrs: {
                        items: row.item.atk.postplant_xvy,
                        fields: _vm.xvyFields
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "thead-top",
                            fn: function() {
                              return [
                                _c(
                                  "b-tr",
                                  [
                                    _c(
                                      "b-th",
                                      {
                                        staticClass: "atk",
                                        attrs: { colspan: "2" }
                                      },
                                      [_vm._v("ATK")]
                                    )
                                  ],
                                  1
                                )
                              ]
                            },
                            proxy: true
                          },
                          {
                            key: "cell(wr)",
                            fn: function(field) {
                              return [
                                _c("div", [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("percentage")(
                                          field.item.wr.wins,
                                          field.item.wr.total
                                        )
                                      )
                                    )
                                  ]),
                                  _c(
                                    "span",
                                    { staticClass: "text-muted ml-1" },
                                    [
                                      _vm._v(
                                        "(" +
                                          _vm._s(field.item.wr.wins) +
                                          " / " +
                                          _vm._s(field.item.wr.total) +
                                          ")"
                                      )
                                    ]
                                  )
                                ])
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    }),
                    _c("AppTable", {
                      attrs: {
                        items: row.item.def.postplant_xvy,
                        fields: _vm.xvyFields
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "thead-top",
                            fn: function() {
                              return [
                                _c(
                                  "b-tr",
                                  [
                                    _c(
                                      "b-th",
                                      {
                                        staticClass: "def",
                                        attrs: { colspan: "2" }
                                      },
                                      [_vm._v("DEF")]
                                    )
                                  ],
                                  1
                                )
                              ]
                            },
                            proxy: true
                          },
                          {
                            key: "cell(wr)",
                            fn: function(field) {
                              return [
                                _c("div", [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("percentage")(
                                          field.item.wr.wins,
                                          field.item.wr.total
                                        )
                                      )
                                    )
                                  ]),
                                  _c(
                                    "span",
                                    { staticClass: "text-muted ml-1" },
                                    [
                                      _vm._v(
                                        "(" +
                                          _vm._s(field.item.wr.wins) +
                                          " / " +
                                          _vm._s(field.item.wr.total) +
                                          ")"
                                      )
                                    ]
                                  )
                                ])
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    })
                  ],
                  1
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }