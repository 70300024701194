var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._m(0),
      _c("AppTable", {
        attrs: { items: _vm.getPreData, fields: _vm.preFields },
        scopedSlots: _vm._u([
          {
            key: "thead-top",
            fn: function() {
              return [
                _c(
                  "b-tr",
                  [
                    _c(
                      "b-th",
                      {
                        staticStyle: { "text-align": "left" },
                        attrs: { colspan: "7" }
                      },
                      [_vm._v(" ULT ROUND EFFECT: PRE-PLANT ")]
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "cell(comp)",
            fn: function(row) {
              return [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover.noninteractive",
                        modifiers: { hover: true, noninteractive: true }
                      }
                    ],
                    staticStyle: {
                      display: "inline-flex",
                      "align-items": "center"
                    },
                    attrs: { title: row.item.title }
                  },
                  [
                    row.item.comp !== "ALL"
                      ? _c("BIconCaretRightFill", {
                          staticClass: "toggle-details-icon",
                          class: row.detailsShowing ? "expanded" : "collapsed",
                          on: { click: row.toggleDetails }
                        })
                      : _vm._e(),
                    row.item.comp === "ALL"
                      ? _c("div", [_vm._v("ALL")])
                      : _c(
                          "div",
                          [
                            _c("AgentComposition", {
                              attrs: { agent_ids: row.item.comp, size: "sm" }
                            })
                          ],
                          1
                        )
                  ],
                  1
                )
              ]
            }
          },
          {
            key: "cell(atk_usage)",
            fn: function(field) {
              return [
                _c("div", [
                  _c("strong", [
                    _vm._v(
                      _vm._s(
                        _vm._f("percentage")(
                          field.item.atk.total,
                          field.item.atk.rounds
                        )
                      )
                    )
                  ]),
                  _c("span", { staticClass: "text-muted ml-1" }, [
                    _vm._v(
                      "(" +
                        _vm._s(field.item.atk.total) +
                        " / " +
                        _vm._s(field.item.atk.rounds) +
                        ")"
                    )
                  ])
                ])
              ]
            }
          },
          {
            key: "cell(atk_plants)",
            fn: function(field) {
              return [
                _c("div", [
                  _c("strong", [_vm._v(_vm._s(field.item.atk.plants))])
                ])
              ]
            }
          },
          {
            key: "cell(atk_defuses)",
            fn: function(field) {
              return [
                _c("div", [
                  _c("strong", [_vm._v(_vm._s(field.item.atk.defuses))])
                ])
              ]
            }
          },
          {
            key: "cell(atk_wr)",
            fn: function(field) {
              return [
                _c("div", [
                  _c("strong", [
                    _vm._v(
                      _vm._s(
                        _vm._f("percentage")(
                          field.item.atk.wins,
                          field.item.atk.total
                        )
                      )
                    )
                  ]),
                  _c("span", { staticClass: "text-muted ml-1" }, [
                    _vm._v(
                      "(" +
                        _vm._s(field.item.atk.wins) +
                        " / " +
                        _vm._s(field.item.atk.total) +
                        ")"
                    )
                  ])
                ])
              ]
            }
          },
          {
            key: "cell(def_usage)",
            fn: function(field) {
              return [
                _c("div", [
                  _c("strong", [
                    _vm._v(
                      _vm._s(
                        _vm._f("percentage")(
                          field.item.def.total,
                          field.item.def.rounds
                        )
                      )
                    )
                  ]),
                  _c("span", { staticClass: "text-muted ml-1" }, [
                    _vm._v(
                      "(" +
                        _vm._s(field.item.def.total) +
                        " / " +
                        _vm._s(field.item.def.rounds) +
                        ")"
                    )
                  ])
                ])
              ]
            }
          },
          {
            key: "cell(def_plants)",
            fn: function(field) {
              return [
                _c("div", [
                  _c("strong", [_vm._v(_vm._s(field.item.def.plants))])
                ])
              ]
            }
          },
          {
            key: "cell(def_defuses)",
            fn: function(field) {
              return [
                _c("div", [
                  _c("strong", [_vm._v(_vm._s(field.item.def.defuses))])
                ])
              ]
            }
          },
          {
            key: "cell(def_wr)",
            fn: function(field) {
              return [
                _c("div", [
                  _c("strong", [
                    _vm._v(
                      _vm._s(
                        _vm._f("percentage")(
                          field.item.def.wins,
                          field.item.def.total
                        )
                      )
                    )
                  ]),
                  _c("span", { staticClass: "text-muted ml-1" }, [
                    _vm._v(
                      "(" +
                        _vm._s(field.item.def.wins) +
                        " / " +
                        _vm._s(field.item.def.total) +
                        ")"
                    )
                  ])
                ])
              ]
            }
          },
          {
            key: "row-details",
            fn: function(row) {
              return [
                _c(
                  "div",
                  { staticStyle: { display: "flex", "flex-direction": "row" } },
                  [
                    _c("AppTable", {
                      attrs: {
                        items: row.item.atk.items,
                        fields: _vm.preItemFields
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "thead-top",
                            fn: function() {
                              return [
                                _c(
                                  "b-tr",
                                  [
                                    _c(
                                      "b-th",
                                      {
                                        staticClass: "atk",
                                        attrs: { colspan: "3" }
                                      },
                                      [_vm._v("ATK")]
                                    )
                                  ],
                                  1
                                )
                              ]
                            },
                            proxy: true
                          },
                          {
                            key: "cell(ults)",
                            fn: function(field) {
                              return [
                                _c(
                                  "div",
                                  [
                                    _c("AgentComposition", {
                                      attrs: {
                                        agent_ids: field.item.ults,
                                        size: "sm"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            }
                          },
                          {
                            key: "cell(win)",
                            fn: function(field) {
                              return [
                                field.item.win
                                  ? _c("div", { staticClass: "won" }, [
                                      _vm._v("✔")
                                    ])
                                  : _c("div", { staticClass: "lost" }, [
                                      _vm._v("✘")
                                    ])
                              ]
                            }
                          },
                          {
                            key: "cell(planted)",
                            fn: function(field) {
                              return [
                                field.item.planted
                                  ? _c("div", { staticClass: "won" }, [
                                      _vm._v("✔")
                                    ])
                                  : _c("div", { staticClass: "lost" }, [
                                      _vm._v("✘")
                                    ])
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    }),
                    _c("AppTable", {
                      attrs: {
                        items: row.item.def.items,
                        fields: _vm.preItemFields
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "thead-top",
                            fn: function() {
                              return [
                                _c(
                                  "b-tr",
                                  [
                                    _c(
                                      "b-th",
                                      {
                                        staticClass: "def",
                                        attrs: { colspan: "3" }
                                      },
                                      [_vm._v("DEF")]
                                    )
                                  ],
                                  1
                                )
                              ]
                            },
                            proxy: true
                          },
                          {
                            key: "cell(ults)",
                            fn: function(field) {
                              return [
                                _c(
                                  "div",
                                  [
                                    _c("AgentComposition", {
                                      attrs: {
                                        agent_ids: field.item.ults,
                                        size: "sm"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            }
                          },
                          {
                            key: "cell(win)",
                            fn: function(field) {
                              return [
                                field.item.win
                                  ? _c("div", { staticClass: "won" }, [
                                      _vm._v("✔")
                                    ])
                                  : _c("div", { staticClass: "lost" }, [
                                      _vm._v("✘")
                                    ])
                              ]
                            }
                          },
                          {
                            key: "cell(planted)",
                            fn: function(field) {
                              return [
                                field.item.planted
                                  ? _c("div", { staticClass: "won" }, [
                                      _vm._v("✔")
                                    ])
                                  : _c("div", { staticClass: "lost" }, [
                                      _vm._v("✘")
                                    ])
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    })
                  ],
                  1
                )
              ]
            }
          }
        ])
      }),
      _vm._m(1),
      _c("AppTable", {
        attrs: { items: _vm.getPostData, fields: _vm.postFields },
        scopedSlots: _vm._u([
          {
            key: "thead-top",
            fn: function() {
              return [
                _c(
                  "b-tr",
                  [
                    _c(
                      "b-th",
                      {
                        staticStyle: { "text-align": "left" },
                        attrs: { colspan: "5" }
                      },
                      [_vm._v(" ULT ROUND EFFECT: POST-PLANT ")]
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "cell(comp)",
            fn: function(row) {
              return [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover.noninteractive",
                        modifiers: { hover: true, noninteractive: true }
                      }
                    ],
                    staticStyle: {
                      display: "inline-flex",
                      "align-items": "center"
                    },
                    attrs: { title: row.item.title }
                  },
                  [
                    row.item.comp !== "ALL"
                      ? _c("BIconCaretRightFill", {
                          staticClass: "toggle-details-icon",
                          class: row.detailsShowing ? "expanded" : "collapsed",
                          on: { click: row.toggleDetails }
                        })
                      : _vm._e(),
                    row.item.comp === "ALL"
                      ? _c("div", [_vm._v("ALL")])
                      : _c(
                          "div",
                          [
                            _c("AgentComposition", {
                              attrs: { agent_ids: row.item.comp, size: "sm" }
                            })
                          ],
                          1
                        )
                  ],
                  1
                )
              ]
            }
          },
          {
            key: "cell(atk_usage)",
            fn: function(field) {
              return [
                _c("div", [
                  _c("strong", [
                    _vm._v(
                      _vm._s(
                        _vm._f("percentage")(
                          field.item.atk.total,
                          field.item.atk.plants
                        )
                      )
                    )
                  ]),
                  _c("span", { staticClass: "text-muted ml-1" }, [
                    _vm._v(
                      "(" +
                        _vm._s(field.item.atk.total) +
                        " / " +
                        _vm._s(field.item.atk.plants) +
                        ")"
                    )
                  ])
                ])
              ]
            }
          },
          {
            key: "cell(atk_wr)",
            fn: function(field) {
              return [
                _c("div", [
                  _c("strong", [
                    _vm._v(
                      _vm._s(
                        _vm._f("percentage")(
                          field.item.atk.wins,
                          field.item.atk.total
                        )
                      )
                    )
                  ]),
                  _c("span", { staticClass: "text-muted ml-1" }, [
                    _vm._v(
                      "(" +
                        _vm._s(field.item.atk.wins) +
                        " / " +
                        _vm._s(field.item.atk.total) +
                        ")"
                    )
                  ])
                ])
              ]
            }
          },
          {
            key: "cell(def_usage)",
            fn: function(field) {
              return [
                _c("div", [
                  _c("strong", [
                    _vm._v(
                      _vm._s(
                        _vm._f("percentage")(
                          field.item.def.total,
                          field.item.def.plants
                        )
                      )
                    )
                  ]),
                  _c("span", { staticClass: "text-muted ml-1" }, [
                    _vm._v(
                      "(" +
                        _vm._s(field.item.def.total) +
                        " / " +
                        _vm._s(field.item.def.plants) +
                        ")"
                    )
                  ])
                ])
              ]
            }
          },
          {
            key: "cell(def_wr)",
            fn: function(field) {
              return [
                _c("div", [
                  _c("strong", [
                    _vm._v(
                      _vm._s(
                        _vm._f("percentage")(
                          field.item.def.wins,
                          field.item.def.total
                        )
                      )
                    )
                  ]),
                  _c("span", { staticClass: "text-muted ml-1" }, [
                    _vm._v(
                      "(" +
                        _vm._s(field.item.def.wins) +
                        " / " +
                        _vm._s(field.item.def.total) +
                        ")"
                    )
                  ])
                ])
              ]
            }
          },
          {
            key: "row-details",
            fn: function(row) {
              return [
                _c(
                  "div",
                  { staticStyle: { display: "flex", "flex-direction": "row" } },
                  [
                    _c("AppTable", {
                      attrs: {
                        items: row.item.atk.items,
                        fields: _vm.postItemFields
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "thead-top",
                            fn: function() {
                              return [
                                _c(
                                  "b-tr",
                                  [
                                    _c(
                                      "b-th",
                                      {
                                        staticClass: "atk",
                                        attrs: { colspan: "2" }
                                      },
                                      [_vm._v("ATK")]
                                    )
                                  ],
                                  1
                                )
                              ]
                            },
                            proxy: true
                          },
                          {
                            key: "cell(ults)",
                            fn: function(field) {
                              return [
                                _c(
                                  "div",
                                  [
                                    _c("AgentComposition", {
                                      attrs: {
                                        agent_ids: field.item.ults,
                                        size: "sm"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            }
                          },
                          {
                            key: "cell(win)",
                            fn: function(field) {
                              return [
                                field.item.win
                                  ? _c("div", { staticClass: "won" }, [
                                      _vm._v("✔")
                                    ])
                                  : _c("div", { staticClass: "lost" }, [
                                      _vm._v("✘")
                                    ])
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    }),
                    _c("AppTable", {
                      attrs: {
                        items: row.item.def.items,
                        fields: _vm.postItemFields
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "thead-top",
                            fn: function() {
                              return [
                                _c(
                                  "b-tr",
                                  [
                                    _c(
                                      "b-th",
                                      {
                                        staticClass: "def",
                                        attrs: { colspan: "2" }
                                      },
                                      [_vm._v("DEF")]
                                    )
                                  ],
                                  1
                                )
                              ]
                            },
                            proxy: true
                          },
                          {
                            key: "cell(ults)",
                            fn: function(field) {
                              return [
                                _c(
                                  "div",
                                  [
                                    _c("AgentComposition", {
                                      attrs: {
                                        agent_ids: field.item.ults,
                                        size: "sm"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            }
                          },
                          {
                            key: "cell(win)",
                            fn: function(field) {
                              return [
                                field.item.win
                                  ? _c("div", { staticClass: "won" }, [
                                      _vm._v("✔")
                                    ])
                                  : _c("div", { staticClass: "lost" }, [
                                      _vm._v("✘")
                                    ])
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    })
                  ],
                  1
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tooltipmain" }, [
      _vm._v(" ? "),
      _c("span", { staticClass: "tooltiptext" }, [
        _c("p", [
          _vm._v(
            "Pre-Plant: Any ults used before the Spike Plant or a Round Win by Elimination (w/o plant)."
          )
        ]),
        _c("p", [
          _vm._v(
            " Usage Rate: Rounds with an ult used over total rounds (w/o filtering of rounds with Ults Ready at Round Start or gained In-Round). "
          )
        ]),
        _c("p", [
          _vm._v(
            "Plants: If any of the rounds with an ult used resulted to a plant."
          )
        ]),
        _c("p", [_vm._v("Win Rate: Round Wins of Rounds with Ults Used.")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tooltipmain" }, [
      _vm._v(" ? "),
      _c("span", { staticClass: "tooltiptext" }, [
        _c("p", [_vm._v("Post-Plant: Any ults used after the Spike Plant.")]),
        _c("p", [
          _vm._v(
            " Usage Rate: Post-Plants with an ult used over total plants (w/o filtering of Post-Plants with Ults Ready at Round Start or gained In-Round). "
          )
        ]),
        _c("p", [_vm._v("Win Rate: Round Wins of Rounds with Ults Used.")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }