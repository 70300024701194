var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BTab",
    _vm._g(
      _vm._b(
        {
          ref: "tab",
          on: { click: _vm.onActivated },
          nativeOn: {
            error: function($event) {
              return _vm.onError.apply(null, arguments)
            },
            loading: function($event) {
              return _vm.onLoading.apply(null, arguments)
            },
            status: function($event) {
              return _vm.onStatus.apply(null, arguments)
            }
          },
          scopedSlots: _vm._u(
            [
              _vm._l(_vm.$scopedSlots, function(_, slot) {
                return {
                  key: slot,
                  fn: function(scope) {
                    return [_vm._t(slot, null, null, scope)]
                  }
                }
              }),
              {
                key: "title",
                fn: function() {
                  return [
                    _vm._t("title", function() {
                      return [_vm._v(" " + _vm._s(_vm.title) + " ")]
                    }),
                    !_vm.lazy || _vm.$refs.tab.localActive
                      ? [
                          _vm.loading
                            ? _c("MiniSpinner")
                            : _vm.error
                            ? _c("BIconExclamationCircle")
                            : _vm.status
                            ? _c("span", [_vm._v(_vm._s(_vm.status))])
                            : _vm._e()
                        ]
                      : _vm._e()
                  ]
                },
                proxy: true
              }
            ],
            null,
            true
          )
        },
        "BTab",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }