var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-wrapper" }, [
    _c(
      "div",
      { staticClass: "stats" },
      [
        _c("WinRateTable", { attrs: { stats: _vm.allStats } }),
        _c("OpeningDuelTable", { attrs: { stats: _vm.allStats } }),
        _c("PostPlantTable", { attrs: { stats: _vm.allStats } }),
        _c("UltTable", { attrs: { stats: _vm.allStats } })
      ],
      1
    ),
    _c("div", { staticClass: "matchSelector" }, [
      _c("h2", [_vm._v("Selected Matches")]),
      _c(
        "div",
        { staticClass: "matchWrapper" },
        [
          _vm._l(_vm.data, function(m) {
            return [
              _c(
                "div",
                {
                  key: m.match,
                  staticClass: "card",
                  staticStyle: { "user-select": "none" }
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selected_matches,
                        expression: "selected_matches"
                      }
                    ],
                    attrs: { type: "checkbox", id: m.match, name: _vm.mapName },
                    domProps: {
                      value: m,
                      checked: Array.isArray(_vm.selected_matches)
                        ? _vm._i(_vm.selected_matches, m) > -1
                        : _vm.selected_matches
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.selected_matches,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = m,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              (_vm.selected_matches = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.selected_matches = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.selected_matches = $$c
                        }
                      }
                    }
                  }),
                  _c(
                    "label",
                    { attrs: { for: m.match } },
                    [
                      _c("AgentComposition", {
                        attrs: {
                          agent_ids: _vm.getCompId(m.team_comp),
                          size: "sm"
                        }
                      }),
                      _c("span", { class: { win: m.win, loss: !m.win } }, [
                        _vm._v(" " + _vm._s(m.round_wins) + " ")
                      ]),
                      _c("span", [_vm._v(" -" + _vm._s(m.round_losses) + " ")])
                    ],
                    1
                  ),
                  _vm._v(" " + _vm._s(m.scrim_date) + " ")
                ]
              )
            ]
          })
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }